import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import './Form.css'; 
import NameInput from './NameInput';
import CityInput from './CityInput';
import StateDropdown from './StateDropdown';
import PhoneNumberInput from './PhoneNumberInput';
import EmailInput from './EmailInput';
import ZipcodeInput from './ZipcodeInput';
import CarToggle from './CarToggle';
import AvailabilityCalendar from './AvailabilityCalendar';
import { collection, addDoc, Timestamp } from "firebase/firestore"; 
import { db, analytics } from '../firebase';
import { logEvent } from "firebase/analytics";
import stateCities from './state_cities.json'; // Import state_cities.json

const Form = () => {
  const [name, setName] = useState('');
  const [city, setCity] = useState('');
  const [state, setState] = useState('');
  const [zipcode, setZipcode] = useState('');
  const [phoneNumber, setPhoneNumber] = useState('');
  const [email, setEmail] = useState('');
  const [hasCar, setHasCar] = useState('');
  const [availability, setAvailability] = useState({});
  const [errors, setErrors] = useState({});
  const [lat, setLat] = useState(null); // State to hold latitude
  const [lng, setLng] = useState(null); // State to hold longitude
  const [agreeToTerms, setAgreeToTerms] = useState(false); // State for terms checkbox
  const [showTerms, setShowTerms] = useState(false); // State to show terms popup
  const navigate = useNavigate();
  useEffect(() => {
    // Function to check URL for doc_id parameter
    const checkUrlForDocId = () => {
      const urlParams = new URLSearchParams(window.location.search);
      const docIdParam = urlParams.get('doc_id');

      if (docIdParam) {
        // If doc_id parameter is present
        navigate(`/one-way-interview?doc_id=${docIdParam}`);
      }
    };

    // Call the function once when component mounts
    checkUrlForDocId();
  }, [navigate]);

  useEffect(() => {
    // Fetch lat and lng based on state and city selection
    if (state && city) {
      const selectedState = stateCities[state];
      if (selectedState) {
        const selectedCity = selectedState.find(item => item.City === city);
        if (selectedCity) {
          setLat(selectedCity.lat);
          setLng(selectedCity.lng);
        }
      }
    }
  }, [state, city]);

  const validateForm = () => {
    const newErrors = {};
    if (!name) newErrors.name = 'This field is required';
    if (!city) newErrors.city = 'This field is required';
    if (!state) newErrors.state = 'This field is required';
    if (!phoneNumber) {
      newErrors.phoneNumber = 'This field is required';
    } else {
      const digitsOnlyPhoneNumber = phoneNumber.replace(/[^0-9]/g, '');
      if (digitsOnlyPhoneNumber.length !== 11) {
        newErrors.phoneNumber = 'Phone number must be exactly 10 digits';
      }
    }
    if (!email) newErrors.email = 'This field is required';
    if (!zipcode) {
      newErrors.zipcode = 'This field is required';
    } else if (zipcode.length !== 5) {
      newErrors.zipcode = 'The zip code must be 5 characters';
    }
    if (hasCar !== 'yes' && hasCar !== 'no') newErrors.hasCar = 'Please choose Yes or No';
    if (Object.keys(availability).length === 0) newErrors.availability = 'Please select at least one time period';
    if (!agreeToTerms) newErrors.agreeToTerms = 'Agree to the terms & conditions';

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleSubmit = async (e) => {
      
    
    e.preventDefault();
    if (!validateForm()) return;

    logEvent(analytics, 'submitted_form');
    window.fbq('track', 'Lead');
    
    const newApplicant = {
      name,
      city,
      state,
      phoneNumber,
      email,
      zipcode,
      hasCar,
      availability,
      lat, // Add latitude to applicant data
      lng, // Add longitude to applicant data
      createdAt: Timestamp.now()
    };

    try {
      const docRef = await addDoc(collection(db, "Applicants"), newApplicant);
      navigate(`/one-way-interview?doc_id=${docRef.id}`);
      setName('');
      setCity('');
      setState('');
      setZipcode('');
      setPhoneNumber('');
      setEmail('');
      setHasCar('');
      setAvailability({});
      setAgreeToTerms(false); // Reset terms checkbox
      setErrors({});
    } catch (error) {
      setErrors({ form: 'Failed to save form data. Please try again.' });
    }
  };

  return (
    <div>
      <form onSubmit={handleSubmit}>
        <h2>Become a Partner</h2>
        <div className="form-container">
          <div className="column">
            <label className="form-label">Name</label>
            <NameInput className="form-input" value={name} onChange={(e) => setName(e.target.value)} />
            {errors.name && <p className="error-message">{errors.name}</p>}
            <label className="form-label">Phone Number</label>
            <PhoneNumberInput className="form-input" value={phoneNumber} onChange={(formattedPhoneNumber) => setPhoneNumber(formattedPhoneNumber)} />
            {errors.phoneNumber && <p className="error-message">{errors.phoneNumber}</p>}
            <label className="form-label">Email</label>
            <EmailInput className="form-input" value={email} onChange={(e) => setEmail(e.target.value)} />
            {errors.email && <p className="error-message">{errors.email}</p>}
          </div>
          <div className="column">
            <label className="form-label">Zip Code</label>
            <ZipcodeInput className="form-input" value={zipcode} onChange={(newValue) => setZipcode(newValue)} />
            {errors.zipcode && <p className="error-message">{errors.zipcode}</p>}
            <label className="form-label">State</label>
            <StateDropdown className="form-input" value={state} onChange={(e) => setState(e.target.value)} />
            {errors.state && <p className="error-message">{errors.state}</p>}
            <label className="form-label">City</label>
            <CityInput className="form-input" state={state} value={city} onChange={(e) => setCity(e.target.value)} />
            {errors.city && <p className="error-message">{errors.city}</p>}
          </div>
          <div className="column">
            <label className="form-label">Do you have a car?</label>
            <CarToggle hasCar={hasCar} setHasCar={setHasCar} required />
            {errors.hasCar && <p className="error-message">{errors.hasCar}</p>}
            <label className="form-label">Availability</label>
            <AvailabilityCalendar availability={availability} setAvailability={setAvailability} />
            {errors.availability && <p className="error-message">{errors.availability}</p>}
          </div>
        </div>
        <div className="terms-container">
          <input
            type="checkbox"
            id="agreeToTerms"
            checked={agreeToTerms}
            onChange={(e) => setAgreeToTerms(e.target.checked)}
          />
          <label htmlFor="agreeToTerms">I agree to the <span className="terms-link" onClick={() => setShowTerms(true)}>terms & conditions</span></label>
          {errors.agreeToTerms && <p className="error-message">{errors.agreeToTerms}</p>}
        </div>
        <button type="submit" className="submit-button">Submit</button>
        {errors.form && <p className="error-message">{errors.form}</p>}
      </form>
      {showTerms && (
        <div className="terms-popup">
          <div className="terms-popup-content">
            <h2>Terms and Conditions</h2>
            <p>By selecting this box, you're on board to champion the deal's closure. Remember, your reward is linked to the actualization of the deal. Be advised that while your dedication is appreciated, we do not offer compensation for travel or meals. Checking this box indicates your acceptance of these terms.</p>
            <button onClick={() => setShowTerms(false)}>Close</button>
          </div>
        </div>
      )}
    </div>
  );
};

export default Form;
