// src/TutorialVideo.js
import React, { useRef, useState, useEffect } from 'react';
import './TutorialVideo.css';

const TutorialVideo = () => {
  const videos = [
    {
      title: "How We Compensate and Represent Our Field Agents",
      url: "https://firebasestorage.googleapis.com/v0/b/flex-opportunity.appspot.com/o/Tutorials%2FTutorial%202.mp4?alt=media&token=6fb3156a-6381-412a-855d-5987a7e5bf2d",
      subtitle: "Tutorial Subs/Tutorial 2.vtt",
      description: "This tutorial explains how we compensate field agents who help acquire deeds. Discover the payment structure based on successful acquisitions, and the best way to present yourself when interacting with homeowners.",
    },
    {
      title: "How to Obtain QuitClaim Deeds: A Step-by-Step Guide",
      url: "https://firebasestorage.googleapis.com/v0/b/flex-opportunity.appspot.com/o/Tutorials%2FTutorial%201.mp4?alt=media&token=c84d386a-4e29-464b-945b-30ff7f7cb2b2",
      subtitle: "Tutorial Subs/Tutorial 1.vtt",
      description: "This tutorial walks you through the process of acquiring quitclaim deeds from homeowners. Learn what a quitclaim deed is, how to effectively communicate with homeowners and neighbors, and the key information you need to gather to make the deed acquisition successful.",
    },
    {
      title: "Ensuring Proper Notarization for Deed Signatures",
      url: "https://firebasestorage.googleapis.com/v0/b/flex-opportunity.appspot.com/o/Tutorials%2FTutorial%203.mp4?alt=media&token=6dd94909-85e9-4c08-9510-2c0e9e4e576e",
      subtitle: "Tutorial Subs/Tutorial 3.vtt",
      description: "This tutorial covers the essential steps for notarizing deed signatures. Learn about the different notary options available, including 24/7 online notaries, local mobile notaries, and services at UPS stores and libraries, ensuring the deed signing process is smooth and authenticated.",
    },
  ];

  return (
    <div className="tutorial-container">
      {videos.map((video, index) => (
        <VideoCard key={index} video={video} />
      ))}
    </div>
  );
};

const VideoCard = ({ video }) => {
  const videoRef = useRef(null);
  const [isPlaying, setIsPlaying] = useState(false);
  const [progress, setProgress] = useState(0);

  const togglePlayPause = () => {
    if (videoRef.current.paused) {
      videoRef.current.play();
      setIsPlaying(true);
    } else {
      videoRef.current.pause();
      setIsPlaying(false);
    }
  };

  const updateProgress = () => {
    const progressValue = (videoRef.current.currentTime / videoRef.current.duration) * 100;
    setProgress(progressValue);
  };

  const handleProgressChange = (e) => {
    const newTime = (e.target.value / 100) * videoRef.current.duration;
    videoRef.current.currentTime = newTime;
  };

  useEffect(() => {
    const videoElement = videoRef.current;
    videoElement.addEventListener('timeupdate', updateProgress);

    return () => {
      videoElement.removeEventListener('timeupdate', updateProgress);
    };
  }, []);

  return (
    <div className="video-card">
      <video ref={videoRef} className="video" onClick={togglePlayPause}>
        <source src={video.url} type="video/mp4" />
        <track
          src={video.subtitle}
          kind="subtitles"
          srcLang="en"
          label="English"
          default
        />
        Your browser does not support the video tag.
      </video>
      <div className="video-controls">
        <button onClick={togglePlayPause} className="play-pause-button">
          {isPlaying ? '❚❚' : '►'}
        </button>
        <input
          type="range"
          className="progress-bar"
          value={progress}
          onChange={handleProgressChange}
        />
      </div>
      <h3>{video.title}</h3>
      <p>{video.description}</p>
    </div>
  );
};

export default TutorialVideo;
