import React, { useState, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import VideoAsk from './VideoAsk'; // Assuming VideoAsk is your custom component
import VideoAudioRecorder from './VideoAudioRecorder'; // Assuming VideoAudioRecorder is your custom component
import './OneWayInterview.css';

const OneWayInterview = () => {
  const [showVideoAsk, setShowVideoAsk] = useState(true);
  const [showVideoRecorder, setShowVideoRecorder] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();
  const docRefId = new URLSearchParams(location.search).get('doc_id');

  useEffect(() => {
    if (!docRefId) {
      navigate('/');
    }
  }, [docRefId, navigate]);

  const handleVideoAskComplete = () => {
    setShowVideoAsk(false);
    setShowVideoRecorder(true);
  };

  const backVideoAsk = () => {
    setShowVideoAsk(true);
    setShowVideoRecorder(false);
  };

  return (
    <div className="one-way-interview-container">
        {/* <img src="./completed.png" alt="Completed" className="thank-you-image" /> */}
      {showVideoAsk && (
        <>
          <p className='one-way-interview'>Get a faster response with our one-way video interview!</p>
          <VideoAsk onRecordAnswer={handleVideoAskComplete} />
        </>
      )}
      {showVideoRecorder && (
        <>
          <p className='one-way-interview'>Get a faster response with our one-way video interview!</p>
          <VideoAudioRecorder docRefId={docRefId} onReturnToVideoAsk={backVideoAsk} />
        </>
      )}
    </div>
  );
};

export default OneWayInterview;
