// ZipcodeInput.js
import React from 'react';
// import axios from 'axios';
const ZipcodeInput = ({ value, onChange }) => {
    // const [error, setError] = useState('');
  
    const handleChange = (e) => {
      const newValue = e.target.value;
      if (/^\d{0,5}$/.test(newValue)) {
        onChange(newValue);
        
      }
    };

  return (
    <div>
      <input
        type="text"
        placeholder="Zip Code"
        value={value}
        onChange={handleChange}
        required
      />
      
    </div>
  );
};

export default ZipcodeInput;
