import React, { useState, useRef, useEffect, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import { getStorage, ref, uploadBytesResumable, getDownloadURL } from 'firebase/storage';
import { collection, addDoc } from 'firebase/firestore'; // Import Firestore functions
import './VideoAudioRecorder.css'; // Importing the CSS file
import { db, analytics } from '../firebase';
import { logEvent } from "firebase/analytics";

const VideoAudioRecorder = ({ docRefId, onReturnToVideoAsk }) => {
  const navigate = useNavigate();
  const [recording, setRecording] = useState(false);
  const [mediaStream, setMediaStream] = useState(null);
  const [mediaRecorder, setMediaRecorder] = useState(null);
  const [recordedChunks, setRecordedChunks] = useState([]);
  const [playbackMode, setPlaybackMode] = useState(false);
  const [permissionDenied, setPermissionDenied] = useState(false);
  const [uploadedVideoUrl, setUploadedVideoUrl] = useState(null);
  const [submitted, setSubmitted] = useState(false);
  const [uploadProgress, setUploadProgress] = useState(0);
  const [countdown, setCountdown] = useState(null);
  const [isUploading, setIsUploading] = useState(false);
  const videoRef = useRef(null);
  const fileInputRef = useRef(null); // Ref for file input element
  const storage = getStorage();
  const [selectedFile, setSelectedFile] = useState(null);

  // Prevent navigation or exiting the page during the upload process
  useEffect(() => {
    const handleBeforeUnload = (event) => {
      if (isUploading) {
        event.preventDefault();
        event.returnValue = '';
      }
    };

    window.addEventListener('beforeunload', handleBeforeUnload);

    return () => {
      window.removeEventListener('beforeunload', handleBeforeUnload);
    };
  }, [isUploading]);

  // Function to handle file upload when permissions are denied
  const handleUploadFile = () => {
    // Click the hidden file input element
    fileInputRef.current.click();
  };

  // Handle file selection from file input
  const handleFileUpload = (event) => {
    const file = event.target.files[0];
    if (file) {
      setSelectedFile(file);
      const video = document.createElement('video');
      video.onloadedmetadata = () => {
        const durationInSeconds = video.duration;
        const maxDuration = 180; // 3 minutes in seconds

        if (durationInSeconds > maxDuration) {
          console.error('Video duration exceeds the limit of 3 minutes');
          alert('Video duration exceeds the limit of 3 minutes. Please choose a shorter video.');
          return;
        }

        // Proceed with handling the video upload
        const url = URL.createObjectURL(file);
        setUploadedVideoUrl(url);
        setPlaybackMode(true);
        setPermissionDenied(false);
        setMediaStream(null); // Reset media stream if needed
      };

      video.src = URL.createObjectURL(file);
      video.load();

      // Optionally, you can check the file type here:
      // const fileType = file.type; // MIME type of the uploaded file
      console.log(file.type)
    }
  };

  useEffect(() => {
    const startRecording = () => {
      if (mediaStream) {
        setRecording(true);
        const recorder = new MediaRecorder(mediaStream);
        setMediaRecorder(recorder);
        recorder.ondataavailable = (event) => {
          if (event.data.size > 0) {
            setRecordedChunks((prev) => [...prev, event.data]);
          }
        };
        recorder.start();
        setTimeout(() => {
          if (recorder.state === "recording") {
            recorder.stop();
            setRecording(false);
            setPlaybackMode(true);
          }
        }, 180000); // 3 minutes timeout
      } else {
        console.error('Cannot start recording: MediaStream is not available.');
      }
    };

    if (countdown === 0) {
      startRecording();
    } else if (countdown !== null) {
      const timerId = setTimeout(() => setCountdown(countdown - 1), 1000);
      return () => clearTimeout(timerId);
    }
  }, [countdown, mediaStream]);

  useEffect(() => {
    const getMedia = async () => {
      try {
        const stream = await navigator.mediaDevices.getUserMedia({ video: true, audio: true });
        setMediaStream(stream);
        videoRef.current.srcObject = stream;
        setCountdown(5);
      } catch (err) {
        setPermissionDenied(true);
      }
    };

    if (!mediaStream && !permissionDenied) {
      getMedia();
    }
  }, [mediaStream, permissionDenied]);

  const handleStopRecording = () => {
    if (mediaRecorder && mediaRecorder.state === "recording") {
      mediaRecorder.stop();
      setRecording(false);
      setPlaybackMode(true);
    }
  };

  const handleUpload = async () => {
    logEvent(analytics, 'uploaded-video');
    setIsUploading(true); // Set uploading state to true
    let fileToUpload = null;
    if (selectedFile) {
      fileToUpload = selectedFile;
    } else if (recordedChunks.length > 0) {
      fileToUpload = new Blob(recordedChunks, { type: 'video/webm' });
    }

    if (!fileToUpload) {
      console.error('No file selected for upload.');
      return;
    }

    const storageRef = ref(storage, `VideoAsk/${docRefId}_${Date.now()}.${fileToUpload.type.split('/')[1]}`);
    const uploadTask = uploadBytesResumable(storageRef, fileToUpload);

    return new Promise((resolve, reject) => {
      uploadTask.on('state_changed',
        (snapshot) => {
          const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
          setUploadProgress(progress);
        },
        (error) => {
          console.error('Upload failed:', error);
          setIsUploading(false); // Reset uploading state
          reject(error);
        },
        async () => {
          try {
            const downloadURL = await getDownloadURL(uploadTask.snapshot.ref);
            setUploadedVideoUrl(downloadURL);
            setSubmitted(true); // Mark as submitted after successful upload
            
            // Save video URL to Firestore
            await addDoc(collection(db, 'VideoAsk'), {
              userID: docRefId,
              videourl: downloadURL
            });

            setIsUploading(false); // Reset uploading state
            navigate(`/thankyou?doc_id=${docRefId}`); // Navigate after successful upload
            resolve();
          } catch (error) {
            console.error('Error getting download URL:', error);
            setIsUploading(false); // Reset uploading state
            reject(error);
          }
        }
      );
    });
  };

  const handleRecordAgain = async () => {
    setPlaybackMode(false);
    setRecordedChunks([]);
    setCountdown(5);
    setMediaStream(null); // Reset media stream to trigger getMedia
    setPermissionDenied(false); // Reset permission denied state
  };

  const handleSubmit = async () => {
    logEvent(analytics, 'recorded-video');
    if (!submitted && uploadedVideoUrl) {
      // Ensure file is uploaded before navigating
      try {
        await handleUpload(); // Ensure file is uploaded
      } catch (error) {
        console.error('Error uploading file:', error);
        // Handle error scenario (e.g., show error message)
      }
    }
  };

  const handleUploadAgain = () => {
    // Reset states to allow for a new upload
    setUploadedVideoUrl(null);
    setPlaybackMode(false);
    setPermissionDenied(false);
    setCountdown(5);
    setRecordedChunks([]);
  };

  const videoSrc = useMemo(() => URL.createObjectURL(new Blob(recordedChunks, { type: 'video/webm' })), [recordedChunks]);

  return (
    <div className="recorder-container">
      {!isUploading && !permissionDenied && <button className="back-button" onClick={onReturnToVideoAsk}>← Back to the Question</button>}
      {!isUploading && permissionDenied && <button className="back-fromupload-button" onClick={onReturnToVideoAsk}>← Back to the Question</button>}
      {uploadedVideoUrl && (
        <div className="uploaded-video">
          <video src={uploadedVideoUrl} controls className="video" />
          {isUploading ? (
            <div className="upload-progress-bar">
              <div className="upload-progress" style={{ width: `${uploadProgress}%` }}>
                {uploadProgress > 0 && `${Math.round(uploadProgress)}%`}
              </div>
            </div>
          ) : (
            <div className="button-container">
              <button className="button submit-button" onClick={handleSubmit}>
                Submit
              </button>
              <button className="button upload-button" onClick={handleUploadAgain}>
                Upload Again
              </button>
            </div>
          )}
        </div>
      )}
      {!uploadedVideoUrl && (
        <div>
          {permissionDenied ? (
            <div className="permission-denied-message">
              <p>Please enable camera & microphone permissions</p>
              <p>(You may have accidentally said no)</p>
              <p>How to fix?</p>
              <ol>
                <li>Locate your browser settings.</li>
                <li>Make sure to set the correct camera & microphone permissions for this website.</li>
                <li>Finally, just refresh this page.</li>
              </ol>
              <p>Or</p>
              <button className="button upload-button" onClick={handleUploadFile}>
                ⤒ Upload a video file
              </button>
              <input
                type="file"
                accept="video/*"
                style={{ display: 'none' }}
                ref={fileInputRef}
                onChange={handleFileUpload}
              />
            </div>
          ) : (
            <div>
              {!playbackMode ? (
                <>
                  <video ref={videoRef} autoPlay playsInline muted></video>
                  {countdown !== null && !recording && <div className="countdown-overlay"><p>{countdown}</p></div>}
                  {/* {recording && <p>Recording...</p>} */}
                  <div className="button-container">
                    {recording && <button className='button stop-button' onClick={handleStopRecording}>Stop Recording</button>}
                  </div>
                </>
              ) : (
                <div>
                  <video
                    src={videoSrc}
                    controls
                    // autoPlay
                    className="video"
                  ></video>
                  {isUploading ? (
                    <div className="upload-progress-bar">
                      <div className="upload-progress" style={{ width: `${uploadProgress}%` }}>
                        {uploadProgress > 0 && `${Math.round(uploadProgress)}%`}
                      </div>
                    </div >
                  ) : (
                    <div className="button-container">
                      <button className="button submit-button" onClick={handleUpload}>Submit</button>
                      <button className="button upload-button" onClick={handleRecordAgain}>Record Again</button>
                    </div>
                  )}
                </div>
              )}
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default VideoAudioRecorder;
