import React from 'react';
import './AboutUs.css'; 
import { Link } from 'react-router-dom';

function AboutUs() {
  return (
    <div className="about-us-container">
      <h2>About Us</h2>
      <p className="intro-text">We Offer Opportunities for Paid Gigs Nationwide.</p>
      <div className="section">
        <h3>Information We Collect</h3>
        <p>We may collect personal information such as your name, email address, phone number, and address when you voluntarily provide it to us through forms, surveys, or other interactions on our website.</p>
      </div>
      <div className="section">
        <h3>About FlexOpportunity</h3>
        <p>FlexOpportunity is an innovative project aimed at building a dynamic and efficient remote back-office team to support our lead generation efforts across the USA. Our mission is to streamline essential tasks such as finding notaries, capturing property images, and conducting door-to-door interactions, ensuring seamless operations and enhanced customer experiences.</p>
      </div>
      <div className="section">
        <h3>Our Approach</h3>
        <p>We believe in harnessing the power of technology and human talent to create scalable solutions for our clients. Our structured approach involves carefully selecting individuals nationwide who are committed to delivering exceptional results remotely.</p>
      </div>
      <div className="section">
        <h3>Why FlexOpportunity</h3>
        <p>We offer a unique opportunity for individuals seeking flexible work arrangements and the chance to contribute to a dynamic and growing team. With our emphasis on professionalism, efficiency, and innovation, FlexOpportunity provides a supportive environment where team members can thrive and make a meaningful impact.</p>
        <p>
          <Link to="/#form" className="join-link"><b>Join FlexOpportunity today and be part of a forward-thinking team that's revolutionizing remote back-office operations in the USA.</b></Link>
        </p>
      </div>
    </div>
  );
}

export default AboutUs;
