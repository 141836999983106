// QnAItem.js
import React, { useState } from 'react';

const QnAItem = ({ question, answer }) => {
  const [isOpen, setIsOpen] = useState(false);

  const toggleAnswer = () => {
    setIsOpen(!isOpen);
  };

  return (
    <div className="qna-item">
      <div className="question" onClick={toggleAnswer}>
        <h5>{question}</h5>
        <div className={`arrow ${isOpen ? 'open' : ''}`}>&#9660;</div>
      </div>
      {isOpen && <p>{answer}</p>}
    </div>
  );
};

export default QnAItem;
