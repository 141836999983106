import React, { useState, useEffect, useCallback } from 'react';
import ClipLoader from "react-spinners/ClipLoader";
import stateCitiesData from './state_cities.json';
import { debounce } from 'lodash';
import './CityInput.css';

const CityInput = ({ state, value, onChange }) => {
  const [cities, setCities] = useState([]);
  const [query, setQuery] = useState('');
  const [loading, setLoading] = useState(false);
  const [currentFocus, setCurrentFocus] = useState(-1);
  const [isValidCity, setIsValidCity] = useState(true);

  // Debounce the input query
  const debouncedQuery = useCallback(
    function debouncedFn(inputValue) {
      setQuery(inputValue);
    },
    [setQuery]
  );

  const debouncedSetQuery = debounce(debouncedQuery, 300);

  useEffect(() => {
    if (state && query.length > 1) {
      setLoading(true);
      const fetchCities = async () => {
        try {
          const stateCities = stateCitiesData[state];
          if (stateCities) {
            const filteredCities = stateCities
              .map(cityObj => cityObj.City)
              .filter(city => city.toLowerCase().includes(query.toLowerCase()));
            setCities(filteredCities);
          } else {
            setCities([]);
          }
        } catch (error) {
          console.error('Error fetching cities:', error);
          setCities([]);
        } finally {
          setLoading(false);
        }
      };
      fetchCities();
    }
  }, [state, query]);

  const handleInputChange = (e) => {
    const inputValue = e.target.value.trim();
    onChange(e);
    debouncedSetQuery(inputValue);
    setCurrentFocus(-1);
    setIsValidCity(cities.includes(inputValue));
  };

  const handleKeyDown = (e) => {
    const items = document.querySelectorAll('.autocomplete-item');
    if (items.length === 0) return; // Ensure items are available

    if (e.key === 'ArrowDown') {
      setCurrentFocus((prevFocus) => (prevFocus + 1) % items.length);
      if (currentFocus >= 0 && currentFocus < items.length) {
        items[currentFocus].classList.remove('autocomplete-active');
      }
      items[(currentFocus + 1) % items.length].classList.add('autocomplete-active');
    } else if (e.key === 'ArrowUp') {
      setCurrentFocus((prevFocus) => (prevFocus > 0 ? prevFocus - 1 : items.length - 1));
      if (currentFocus >= 0 && currentFocus < items.length) {
        items[currentFocus].classList.remove('autocomplete-active');
      }
      items[(currentFocus - 1 + items.length) % items.length].classList.add('autocomplete-active');
    } else if (e.key === 'Enter') {
      e.preventDefault();
      if (currentFocus >= 0 && currentFocus < items.length) {
        items[currentFocus].click();
      } else {
        debouncedSetQuery(e.target.value.trim());
      }
    }
  };

  const handleInputBlur = () => {
    if (!cities.includes(value)) {
      onChange({ target: { value: '' } });
    }
    setTimeout(() => {
      setCities([]);
    }, 200);
  };

  return (
    <div className="city-input-container">
      <input
        type="text"
        placeholder="City"
        value={value}
        onChange={handleInputChange}
        onKeyDown={handleKeyDown}
        onBlur={handleInputBlur}
        className={`city-input ${isValidCity ? '' : 'input-error'}`}
      />
      {loading && (
        <div className="loading-indicator">
          <ClipLoader size={20} color="#123abc" />
        </div>
      )}
      {cities.length > 0 && !loading && (
        <div className="autocomplete-items">
          {cities.map((city, index) => (
            <div
              key={city}
              className={`autocomplete-item ${currentFocus === index ? 'autocomplete-active' : ''}`}
              onClick={() => {
                onChange({ target: { value: city } });
                setIsValidCity(true);
              }}
            >
              {city}
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export default CityInput;
