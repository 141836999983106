import React, { useState } from 'react';
import './AvailabilityCalendar.css'; // Make sure to add necessary CSS

const AvailabilityCalendar = ({ availability, setAvailability }) => {
  const [isOpen, setIsOpen] = useState(false);

  const days = ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday'];
  const daysAbbreviated = {
    Monday: 'Mon',
    Tuesday: 'Tues',
    Wednesday: 'Wed',
    Thursday: 'Thurs',
    Friday: 'Fri',
    Saturday: 'Sat',
    Sunday: 'Sun',
  };
  const times = ['8 AM - 12 PM', '12 PM - 4 PM', '4 PM - 8 PM'];
  const timeLabels = {
    '8 AM - 12 PM': 'Morning',
    '12 PM - 4 PM': 'Afternoon',
    '4 PM - 8 PM': 'Evening',
  };

  const handleCheckboxChange = (day, time) => {
    setAvailability((prev) => {
      const currentDayAvailability = prev[day]?.split(' & ') || [];
      const updatedDayAvailability = currentDayAvailability.includes(timeLabels[time])
        ? currentDayAvailability.filter((t) => t !== timeLabels[time])
        : [...currentDayAvailability, timeLabels[time]];

      const newAvailability = { ...prev };
      if (updatedDayAvailability.length) {
        newAvailability[day] = updatedDayAvailability.join(' & ');
      } else {
        delete newAvailability[day];
      }

      return newAvailability;
    });
  };

  const handleSelectAllChange = (day) => {
    const allSelected = times.every((time) => availability[day]?.includes(timeLabels[time]));
    setAvailability((prev) => {
      const newAvailability = { ...prev };
      if (allSelected) {
        delete newAvailability[day];
      } else {
        newAvailability[day] = times.map((time) => timeLabels[time]).join(' & ');
      }
      return newAvailability;
    });
  };

  const handleSave = () => {
    setIsOpen(false);
  };

  return (
    <div>
      <button type="button" className="availability-button" onClick={() => setIsOpen(!isOpen)}>
        Select Availability
      </button>
      {isOpen && (
        <div className="popup">
          <div className="popup-content">
            <button className="exit-button" onClick={() => setIsOpen(false)}>X</button>
            <div className="days-container">
              {days.map((day) => (
                <div key={day} className="day-column">
                  <h3>{day}</h3>
                  <label className="select-all">
                    <input
                      type="checkbox"
                      checked={times.every((time) => availability[day]?.includes(timeLabels[time]))}
                      onChange={() => handleSelectAllChange(day)}
                    />
                    <b>Select All</b>
                  </label>
                  {times.map((time) => (
                    <label key={time} className="time-slot">
                      <input
                        type="checkbox"
                        checked={availability[day]?.includes(timeLabels[time]) || false}
                        onChange={() => handleCheckboxChange(day, time)}
                      />
                      {time}
                    </label>
                  ))}
                </div>
              ))}
            </div>
            <button type="button" className="save-button" onClick={handleSave}>Save</button>
          </div>
        </div>
      )}
      {!isOpen && (
        <div className="availability-summary">
          {Object.keys(availability).length === 0 ? (
            <h4>No availability selected</h4>
          ) : (
            days.map((day) =>
              availability[day] ? (
                <div key={day} className="day-summary">
                  <h4>{daysAbbreviated[day]}:</h4>
                  <p>{availability[day]}</p>
                </div>
              ) : null
            )
          )}
        </div>
      )}
    </div>
  );
};

export default AvailabilityCalendar;
