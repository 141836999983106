import React from 'react';

const PhoneNumberInput = ({ value, onChange }) => {
  const handleChange = (e) => {
    const inputValue = e.target.value;
    let phoneNumber = inputValue.replace(/[^0-9]/g, '');

    if (!phoneNumber.startsWith('1')) {
      phoneNumber = `1${phoneNumber}`;
    }

    phoneNumber = phoneNumber.replace(/^1/, '');

    if (phoneNumber.length > 0 && (phoneNumber[0] === '0' || phoneNumber[0] === '1')) {
      phoneNumber = phoneNumber.slice(1);
    }

    if (phoneNumber.length > 10) {
      phoneNumber = phoneNumber.slice(0, 10);
    }

    let formattedPhoneNumber = `+1 ${phoneNumber}`;

    if (phoneNumber.length === 10) {
      formattedPhoneNumber = `+1 (${phoneNumber.slice(0, 3)}) ${phoneNumber.slice(3, 6)}-${phoneNumber.slice(6, 10)}`;
    }

    onChange(formattedPhoneNumber);
  };

  return (
    <input
      type="tel"
      placeholder="Phone Number (US)"
      value={value}
      onChange={handleChange}
      maxLength={18}
      required
    />
  );
};

export default PhoneNumberInput;
