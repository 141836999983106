import React from 'react';
import './App.css';
import Form from './components/Form';
import { Carousel } from 'react-responsive-carousel';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import QnAItem from './QnAItem';
import { BrowserRouter as Router, Route, Routes, Link } from 'react-router-dom';
import AboutUs from './AboutUs';
import ThankYou from './ThankYou';
import OneWayInterview from './components/OneWayInterview'

function App() { 
  return (
    <Router>
      <div className="App">
        <header className="App-header">
          <div className="logo">
            <Link to="/">
              <img src="/logo.png" alt="Logo" />
            </Link>
          </div>
          <nav className="App-nav">
            <Link to="/AboutUs">About Us</Link>
          </nav>
        </header>
        <main>
          <Routes>
            <Route path="/AboutUs" element={<AboutUs />} />
            <Route path="/ThankYou" element={<ThankYou />} />
            <Route path="/One-Way-Interview" element={<OneWayInterview />}/>
            <Route path="/" element={
              <>
                <section className="welcome-section">
                  <div className="welcome-text">
                    <h1>Local Opportunities for Paid Gigs</h1>
                    <p>Discover flexible opportunities that fit your schedule, complete tasks nearby, and enjoy competitive pay. Join today for a hassle-free way to earn money on your own terms!</p>
                    <a href="#form"><button className="partner-button">Partner With Us</button></a>
                  </div>
                </section>
                <div className="highlighted-line">
                  <p>Join our nationwide network of partners! A leading real estate agency is seeking locals for paid gigs!</p>
                </div>
                <section className="carousel-section">
                  <Carousel
                    showArrows={true}
                    autoPlay={true}
                    infiniteLoop={true}
                    showThumbs={false}
                    showStatus={false}
                    interval={3000}
                  >
                    <div>
                      <p className="carousel-text"><b>33 % </b> Female Partners</p>
                    </div>
                    <div>
                      <p className="carousel-text"><b>2000+</b> Partners</p>
                    </div>
                    <div>
                      <p className="carousel-text"><b>99+</b> Tasks</p>
                    </div>
                    <div>
                      <p className="carousel-text"><b>250+</b> Cities Nationwide</p>
                    </div>
                    <div>
                      <p className="carousel-text">Get paid quickly and securely.</p>
                    </div>
                  </Carousel>
                </section>
                <section id="form" className="form-section">
                  <Form />
                </section>
                <section className="information-section">
                  <div className="information-column">
                    <img src="/image1.jpg" alt="info1" />
                    <p>Unlock the gateway to easy money with our <b>Deal Facilitator</b> position. Visit properties, network with key individuals, and streamline deals effortlessly.<br></br>Make easy money as a Deal Facilitator! Visit properties, talk to homeowners, and get paid $400 for each successful deal you help make happen.</p>
                  </div>
                  <div className="information-column">
                    <img src="/image2.jpg" alt="info2" />
                    <p>Transform your local connections into money with our <b>Deal Facilitator</b> position. By simply presenting our services in local opportunities and covering leads in your area, you could potentially earn a full commission of $400 for each successful deal. Take charge of your financial future today and leverage your network to unlock unlimited earning potential!</p>
                  </div>
                </section>
                <div className="highlighted-line">
                  <p>You will get paid $400-$500 for each closed deal!</p>
                </div>
                <section className="qna-section">
                  <QnAItem
                    question="How do I get started with your gigs program?"
                    answer="Our company offers tasks and gigs throughout the States, ensuring there's something for everyone, no matter where you are getting started is simple! Just fill out the form with your information, and our team will reach out to guide you through the onboarding process. We're excited to have you join us on this journey!."
                  />
                  <QnAItem
                    question="What kind of compensation or payment structure do you offer for completed tasks?"
                    answer="Instant rewards! We offer hassle-free cash payments, handed to you directly or via secure mobile apps, as soon as you complete your tasks. No delays, no hassle, just quick and reliable compensation for your hard work."
                  />
                  <QnAItem
                    question="What are the qualifications or requirements to become a partner?"
                    answer="The qualifications may vary depending on the specific gig, but generally, we look for individuals with excellent communication skills, a proactive attitude, and a willingness to learn."
                  />
                  <QnAItem
                    question="Do I need any special training or certifications to participate in certain gigs?"
                    answer="Details about any necessary requirements will be provided with each gig listing."
                  />
                  <QnAItem
                    question="What level of flexibility is there in terms of scheduling and completing tasks?"
                    answer="We understand the importance of flexibility. Many of our gigs offer flexible scheduling options, allowing you to work at your own pace and according to your availability."
                  />
                  <QnAItem
                    question="Are there any opportunities for advancement or growth within your gigs program?"
                    answer="Yes, we offer opportunities for advancement and growth within our gigs program. Depending on performance and dedication, partners may have access to higher-paying gigs or leadership roles within our community."
                  />
                </section>
              </>
            } />
          </Routes>
        </main>
        <footer className="footer">
          <div className="footer-column">
            <h4>Contact Numbers</h4>
            <p>(848) 246-2813</p>
            <p>(423) 529-1205</p>
          </div>
          <div className="footer-column">
            <h4>Opening Hours (EST)</h4>
            <p><b>Monday - Friday:</b> 9am - 6pm</p>
            <p><b>Saturday:</b> 10am - 4pm</p>
            <p><b>Sunday:</b> Closed</p>
          </div>
        </footer>
      </div>
    </Router>
  );
}

export default App;
