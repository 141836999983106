import React, { useState, useRef, useEffect, useCallback } from 'react';
import './VideoAsk.css';
import ClipLoader from 'react-spinners/ClipLoader';
import { analytics } from '../firebase';
import { logEvent } from "firebase/analytics";

const VideoAsk = ({ onRecordAnswer }) => {
  const [isLoading, setIsLoading] = useState(true);
  const [showRecordButton, setShowRecordButton] = useState(false);
  const [firstTimePlayed, setFirstTimePlayed] = useState(false);
  const videoRef = useRef(null);

  const handleVideoLoaded = () => {
    setIsLoading(false);
    // Add event listeners for both click and touchstart
    videoRef.current.addEventListener('click', handleVideoClick);
    videoRef.current.addEventListener('touchstart', handleVideoClick);
  };

  const handleTimeUpdate = () => {
    if (videoRef.current.currentTime >= 20 && !showRecordButton) {
      setShowRecordButton(true);
    }
  };

  const handleVideoClick = useCallback(() => {
    const currentVideo = videoRef.current;

    if (!firstTimePlayed) {
      currentVideo.muted = false;
      currentVideo.currentTime = 0;

      // Delayed play for first time
      setTimeout(() => {
        currentVideo.play()
          .then(() => {
            setFirstTimePlayed(true);
          })
          .catch((error) => {
            console.error('Error playing playback:', error);
          });
      }, 200); // Adjust delay time as needed
    } else {
      if (currentVideo.paused || currentVideo.ended) {
        currentVideo.play();
      } else {
        currentVideo.pause();
      }
    }
  }, [firstTimePlayed]);

  useEffect(() => {
    const currentVideoRef = videoRef.current;

    currentVideoRef.addEventListener('click', handleVideoClick);
    currentVideoRef.addEventListener('touchstart', handleVideoClick);

    return () => {
      if (currentVideoRef) {
        currentVideoRef.removeEventListener('click', handleVideoClick);
        currentVideoRef.removeEventListener('touchstart', handleVideoClick);
      }
    };
  }, [handleVideoClick]);

  return (
    <div className="video-ask-container">
      {isLoading && (
        <div className="spinner-container">
          <ClipLoader size={100} color={"#123abc"} loading={isLoading} />
        </div>
      )}

      <div className="video-wrapper">
        <video
          ref={videoRef}
          src="https://firebasestorage.googleapis.com/v0/b/flex-opportunity.appspot.com/o/Interview%20Q1.mp4?alt=media&token=f6895a99-75d4-43a1-99fc-754dad912aee"
          controls
          loop
          autoPlay
          muted
          controlsList="nodownload"
          className="video"
          onLoadedMetadata={handleVideoLoaded}
          onTimeUpdate={handleTimeUpdate}
        >
          <track
            src="caption.vtt"
            kind="captions"
            srcLang="en"
            label="English"
            default
          />
        </video>
        {!isLoading && showRecordButton && (
          <button
          className="button record-button"
          onClick={() => {
            onRecordAnswer();
            logEvent(analytics, 'respond-button');
          }}
        >
          Record Your Answer
        </button>
        )}
      </div>

      <p className="caption">Please watch the video and record your answer.</p>
    </div>
  );
};

export default VideoAsk;
