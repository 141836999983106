import React from 'react';
import './CarToggle.css';

const CarToggle = ({ hasCar, setHasCar }) => {
  return (
    <div className="car-toggle">
      <label className="car-toggle-option">
        <input
          type="radio"
          name="carToggle"
          value="yes"
          checked={hasCar === 'yes'}
          onChange={() => setHasCar('yes')}
          
        />
        <span className={`toggle-label ${hasCar === 'yes' ? 'active' : ''}`}>Yes!</span>
      </label>
      <label className="car-toggle-option">
        <input
          type="radio"
          name="carToggle"
          value="no"
          checked={hasCar === 'no'}
          onChange={() => setHasCar('no')}
          
        />
        <span className={`toggle-label ${hasCar === 'no' ? 'active' : ''}`}>No</span>
      </label>
    </div>
  );
};

export default CarToggle;
