import React from 'react';
import './ThankYou.css';

import TutorialVideo from './TutorialVideo';

// import completedImage from './completed.png';  

const ThankYou = () => {
  // const [showRecorder, setShowRecorder] = useState(false);

  // const handleRecordAnswer = () => {
  //   setShowRecorder(true);
  // };
  return (
    
    <div className="thank-you-container">
      <img src="./completed.png" alt="Completed" className="thank-you-image" />
      <h1 className="thank-you-heading">Thank you for applying!</h1>
      <p className="thank-you-paragraph">View these tutorials to learn what we do!</p>
      {/* {!showRecorder ? (
        <VideoAsk onRecordAnswer={handleRecordAnswer} />
      ) : (
        <VideoAudioRecorder />
      )} */}
      <TutorialVideo />

    </div>
  );
};

export default ThankYou;
